import React, { CSSProperties } from 'react';

import Button from 'react-bootstrap/Button';

interface Props {
  opaque?: boolean,
  dark?: boolean,
  className?: string,
  href?: string,
}

interface State {

}

interface OpaqueVariant {
  opaque:
  | 'primary'
  | 'secondary'
  | 'success'
  | 'danger'
  | 'warning'
  | 'info'
  | 'dark'
  | 'light'
  | 'link'
  | 'outline-primary'
  | 'outline-secondary'
  | 'outline-success'
  | 'outline-danger'
  | 'outline-warning'
  | 'outline-info'
  | 'outline-dark'
  | 'outline-light',
  transparent:
  | 'primary'
  | 'secondary'
  | 'success'
  | 'danger'
  | 'warning'
  | 'info'
  | 'dark'
  | 'light'
  | 'link'
  | 'outline-primary'
  | 'outline-secondary'
  | 'outline-success'
  | 'outline-danger'
  | 'outline-warning'
  | 'outline-info'
  | 'outline-dark'
  | 'outline-light',
}

interface BackgroundVariant {
  dark: OpaqueVariant,
  light: OpaqueVariant,
}

const darkVariant: BackgroundVariant = {
  light: {
    opaque: 'primary',
    transparent: 'outline-secondary',
  },
  dark: {
    opaque: 'primary',
    transparent: 'outline-light',
  }
}

const Styles: { [id: string]: CSSProperties; } = {
  button: {
    borderRadius: "20px",
    paddingTop: ".1rem",
    paddingBottom: ".13rem",
    paddingLeft: "1em",
    paddingRight: "1em"
  },
}

export default class RoundButton extends React.Component<Props, State> {
  render() {
    const opaqueVariant = darkVariant[this.props.dark ? 'dark' : 'light']
    const variant = opaqueVariant[this.props.opaque ? 'opaque' : 'transparent']

    return (
      <Button href={this.props.href} style={Styles.button} className={"text-nowrap" + this.props.className} variant={variant}>{this.props.children}</Button>
    );
  }
}
