import React from 'react';

import { FontAwesomeIcon, FontAwesomeIconProps } from '@fortawesome/react-fontawesome'
import { IconProp } from '@fortawesome/fontawesome-svg-core';

interface Props {
  className?: string,
  size?: FontAwesomeIconProps["size"],
  color?: string,
}

interface State {

}

export default abstract class FaIcon extends React.Component<Props, State> {
  protected abstract getIconProp(): IconProp;

  render() {
    return (
      <FontAwesomeIcon
        icon={this.getIconProp()}
        size={this.props.size}
        className={this.props.className}
        color={this.props.color}
      />
    );
  }
}
