export interface MarkdownRemarkNode {
  fields: MarkdownRemarkFields
  frontmatter: FrontMatter
  html?: string
}

export interface MarkdownRemarkFields {
  slug: string
}

export interface FrontMatter {
  title: string
  date: string
  short: string
  subtitle: string
  key: string
  medium: string
  keywords: string
}

export class ArticleInfo {
  title: string
  date: string
  short: string
  subtitle: string
  medium: string
  slug: string
  keywords: string

  constructor(title: string, subtitle: string, date: string, short: string, medium: string, keywords: string, slug: string) {
    this.title = title
    this.subtitle = subtitle
    this.date = date
    this.short = short
    this.slug = slug
    this.medium = medium
    this.keywords = keywords
  }

  static from(node: MarkdownRemarkNode): ArticleInfo {
    return new ArticleInfo(
      node.frontmatter.title,
      node.frontmatter.subtitle,
      node.frontmatter.date,
      node.frontmatter.short,
      node.frontmatter.medium,
      node.frontmatter.keywords,
      node.fields.slug,
    )
  }
}
