import { withPrefix } from 'gatsby'

// import other settings from ../../settings.js as default settings Defaults
import Defaults from '../../settings.js'

export default class Settings {
  static domain = Defaults.SITE_URL
  static linkedInLink = Defaults.LINKEDIN_URL
  static author = Defaults.AUTHOR
  static keywords = Defaults.KEYWORDS
  static slogan = Defaults.SLOGAN
  static subslogan = Defaults.SUB_SLOGAN
  static articlesDescription = Defaults.ARTICLE_DESCRIPTION
  static street = Defaults.STREET
  static city = Defaults.CITY
  static mail = Defaults.MAIL
  static phone = Defaults.PHONE
  static copyright = Defaults.COPYRIGHT
  static twitterHandle = Defaults.TWITTER_HANDLE
  static defaultImageDir = Defaults.ICON_PATH
  static aboutTitle = Defaults.ABOUT_TITLE
  static aboutTexts: string[] = Defaults.ABOUT_TEXTS
  static imagePath: string = Defaults.IMAGE_PATH
  static defaultSquareThumbnailFilename: string = Defaults.SQUARE_THUMBNAIL_FILENAME
  static defaultSharingThumbnailFilename: string = Defaults.DEFAULT_THUMBNAIL_FILENAME
  static defaultHiresSquareThumbnailFilename: string = Defaults.HIGHRES_SQUARE_THUMBNAIL_FILENAME
  static defaultHiresDefaultThumbnailFilename: string = Defaults.HIGHRES_DEFAULT_THUMBNAIL_FILENAME
  static defaultHiresSquareThumbnail = {
    name: this.defaultHiresSquareThumbnailFilename,
    type: 'image/jpeg',
    width: 960,
    height: 960,
  }
  static defaultHiresDefaultThumbnail = {
    name: this.defaultHiresDefaultThumbnailFilename,
    type: 'image/jpeg',
    width: 1920,
    height: 960,
  }
  static defaultSquareThumbnail = {
    name: this.defaultSquareThumbnailFilename,
    type: 'image/jpeg',
    width: 512,
    height: 512
  }
  static defaultSharingThumbnail = {
    name: this.defaultSharingThumbnailFilename,
    type: 'image/jpeg',
    width: 1200,
    height: 630
  }

  static getAbsoluteUrl(url?: string, url2?: string, url3?: string) {
    return Settings.addDomain(Settings.addBasename(url, url2, url3))
  }

  static addBasename(url?: string, url2?: string, url3?: string) {
    const relativeUrl3 = url3 ? Settings.addBackslashAtFront(url3) : ''
    const relativeUrl2 = url2 ? Settings.addBackslashAtFront(url2) : ''
    const relativeUrl = Settings.addBackslashAtFront(url) + relativeUrl2 + relativeUrl3

    return withPrefix(relativeUrl)
  }

  static addDomain(url?: string) {
    const relativeUrl = Settings.addBackslashAtFront(url)

    return Settings.domain + relativeUrl
  }

  private static addBackslashAtFront(url?: string): string {
    if (!url || url.length === 0) {
      return '/'
    }

    if (url.charAt(0) !== '/') {
      return `/${url}`
    }

    return url
  }
}
