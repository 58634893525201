'use strict'

const Settings = {
  PATH_PREFIX: '',
  SITE_URL: `https://tobiaskaechele.com`, // no backslash at the end!
  NAME: "Tobias C. Kaechele",
  AUTHOR: "Tobias C. Kaechele",
  ICON_PATH: "/images",
  IMAGE_PATH: "/images",
  DEFAULT_THUMBNAIL_FILENAME: "thumbnail2x1_0.5.jpg",
  SQUARE_THUMBNAIL_FILENAME: "thumbnail1x1_0.5.jpg",
  HIGHRES_DEFAULT_THUMBNAIL_FILENAME: "thumbnail2x1.jpg",
  HIGHRES_SQUARE_THUMBNAIL_FILENAME: "thumbnail1x1.jpg",
  DESCRIPTION: 'Creating AI solutions that transform businesses. Passionately.',
  KEYWORDS: 'Kaechele, Tobias Kaechele, Tobias C Kaechele, Tobias C. Kaechele, Tobias Kächele, Kächele, Tobias C. Kächele, Tobias Christian Kächele, artificial intelligence, leadership, management, senior manager, director, software engineering, enterprise manager, data science, machine learning, ml, ai, management, leadership, team lead, tech lead, software development, data science, agile methodologies',
  LINKEDIN_URL: 'https://www.linkedin.com/in/tobiaskaechele',
  SLOGAN: 'AI is the future. Are you ready?',
  SUB_SLOGAN: 'Unlock the full potential of AI to revolutionize your business',
  ARTICLE_DESCRIPTION: 'Read my blog to find out more about how I approach building AI solutions, leading international teams, and other related topics.',
  STREET: 'Berliner Str. 39',
  CITY: '10715 Berlin',
  MAIL: 'tobiaskaechele@web.de',
  PHONE: '+49 30 91561683',
  COPYRIGHT: '',
  TWITTER_HANDLE: 'tobiaskaechele', // no @ at the beginning!
  ABOUT_TITLE: 'Tobias C. Kaechele is ...',
  ABOUT_TEXTS: [
    'The Director of AI & Cloud at Moodagent|, leading a department of multiple international teams with 20 professionals.',
    'A Senior Manager and former Startup-CEO| with more than 8 years of experience in IT leadership focusing on AI, Cloud, and Software Engineering.',
    'A top academic| with degrees in Computer Science and Cognitive Neurosciences (MSc) from the top two universities in Germany.',
    'A coach and empathic leader| who loves to scale teams, grow professionals, and create a positive work-environment to attract the best talent on the market.',
    'An AI-enthusiast| with hands-on expertise in machine learning and data science who started coding at the age of 12.',
    'A certified Scrum Agile Leader|, Product Owner, and Scrum Master who strongly believes in agile methodologies because only great teams make great products.',
  ],
}
Settings.COPYRIGHT = `Copyright © 2024 ${Settings.AUTHOR}. All rights reserved.`

module.exports = Settings
