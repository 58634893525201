import React, { CSSProperties } from 'react';

import RoundButton from './RoundButton';
import FaIconLinkedin from './icons/FaIconLinkedin';
import Banner from './Banner';
import Settings from '../utils/Settings';

interface Props {
}

interface State {
}

const Styles: { [id: string]: CSSProperties; } = {
  text: { fontFamily: 'Roboto', fontSize: "1.05rem", lineHeight: "1.05em", fontWeight: 400 },
  textSmaller: { fontFamily: 'Roboto', fontSize: "0.9rem", lineHeight: "1.05em", fontWeight: 400 },
  copyright: { fontSize: "0.8em" },
  footer: {
    backgroundColor: "#151D2D",
    marginTop: "auto",
  },
  header: {
    fontFamily: 'Asap',
    fontWeight: 700,
    lineHeight: "1.05em",
    opacity: 0.2,
  },
  image: {
    width: '100%',
  },
  borderRight: {
    borderRightStyle: 'solid', borderRightColor: 'rgba(255,255,255,0.7)', borderRightWidth: '1px',
  },
}

export default class SiteFooter extends React.Component<Props, State> {
  render() {
    return (
      <footer className="footer text-light mt-auto" style={Styles.footer} id="contact">

        <div className="container d-none d-lg-block" style={{ paddingTop: 80 }}>
          <div className="row equal">
            <div className="col-2 pl-0 d-flex justify-content-start align-items-center" style={Styles.borderRight}>
              <a className="text-decoration-none tk-light-link" href={Settings.addBasename()}><Banner bigger textColor='white' logoColor='white' /></a>
            </div>

            <div className="col-3 d-flex justify-content-center align-items-center">
              <RoundButton dark href={Settings.linkedInLink}>Find me on Linked<span style={{ marginLeft: ".1em" }}><FaIconLinkedin /></span></RoundButton>
            </div>
          </div>
        </div>

        <div className="container d-none d-md-block d-lg-none" style={{ paddingTop: 80 }}>
          <div className="row equal">
            <div className="col-3 pl-0 d-flex justify-content-start align-items-center" style={Styles.borderRight}>
              <a className="text-decoration-none tk-light-link" href={Settings.addBasename()}><Banner bigger textColor='white' logoColor='white' /></a>
            </div>

            <div className="col-4 d-flex justify-content-center align-items-center">
              <RoundButton dark href={Settings.linkedInLink}>Find me on Linked<span style={{ marginLeft: ".1em" }}><FaIconLinkedin /></span></RoundButton>
            </div>
          </div>
        </div>


        <div className="container d-block d-md-none pt-5">
          <div className="row">
            <div className="col-5 d-flex flex-column justify-content-start align-items-start" >
              <a className="text-decoration-none tk-light-link" href={Settings.addBasename()}>
                <Banner className="p-0" bigger textColor='white' logoColor='white' />
              </a>
            </div>
            <div className="col-7 d-flex flex-column justify-content-center align-items-end" style={Styles.textSmaller}>
              <RoundButton dark href={Settings.linkedInLink}>Find me on Linked<span style={{ marginLeft: ".1em" }}><FaIconLinkedin /></span></RoundButton>
            </div>
          </div>
        </div>


        <div className="container d-none d-sm-block pt-5 pb-3">
          <div className="row">
            <div className="col-12 text-white" style={Styles.copyright}>
              {Settings.copyright}
            </div>
          </div>
        </div>

        <div className="container d-block d-sm-none pt-5 pb-3">
          <div className="row">
            <div className="col-12 text-white" style={Styles.copyright}>
              {Settings.copyright}
            </div>
          </div>
        </div>
      </footer>
    );
  }
}
