import React, { CSSProperties } from 'react';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';

import Banner from './Banner';
import Settings from '../utils/Settings';

interface Props {
  variant?: 'light' | 'dark',
  highlightHome?: boolean,
  style?: CSSProperties,
  className?: string,
}

interface State {

}

export default class Navigation extends React.Component<Props, State> {
  render() {
    const isDark = this.props.variant === 'dark'
    const highlightHome = this.props.highlightHome === true

    const homeClassNames = highlightHome ? 'text-primary ' : (isDark ? 'text-white ' : '')
    const bannerTextColor = isDark ? 'white' : 'primary'
    const linkClassNames = isDark ? 'text-white ' : ''

    const darkLinkClassNames = isDark ? 'tk-dark-link ' : 'tk-light-link '

    return (
      <div className={`container-fluid p-0 p-sm-2 p-md-3 ${this.props.className || ''}`} style={this.props.style}>
        <Navbar bg="transparent" variant={this.props.variant} className="d-none d-sm-block pl-0 pl-sm-0 pr-1 pr-sm-2">
          <div className="container-fluid" style={{ overflow: 'hidden' }}>
            <Navbar.Brand href={Settings.addBasename()} className={darkLinkClassNames}>
              <Banner textColor={bannerTextColor} />
            </Navbar.Brand>
            <Nav className="ml-auto" >
              <Nav.Link href={Settings.addBasename()} className={`${homeClassNames}${darkLinkClassNames} pl-0 pr-1 pr-sm-2 pr-md-2 pr-lg-3`} style={Styles.linkText}>Home</Nav.Link>
              <Nav.Link href={Settings.addBasename("#about")} className={`${linkClassNames}${darkLinkClassNames} pr-1 pr-sm-2 pr-md-2 pr-lg-3`} style={Styles.linkText}>About</Nav.Link>
              <Nav.Link href={Settings.addBasename("#articles")} className={`${linkClassNames}${darkLinkClassNames} pr-1 pr-sm-2 pr-md-2 pr-lg-3`} style={Styles.linkText}>Articles</Nav.Link>
              <Nav.Link href={Settings.linkedInLink} className={`${linkClassNames}${darkLinkClassNames}`} style={Styles.linkText}>LinkedIn</Nav.Link>
            </Nav>
          </div>
        </Navbar>

        <Navbar bg="transparent" variant={this.props.variant} className="d-block d-sm-none pl-0 pl-sm-0 pr-1 pr-sm-2">
          <div className="container-fluid" style={{ overflow: 'hidden' }}>
            <Navbar.Brand href={Settings.addBasename()} className={darkLinkClassNames}>
              <Banner textColor={bannerTextColor} />
            </Navbar.Brand>
            <Nav className="ml-auto" style={{ fontSize: "0.9rem" }} >
              <Nav.Link href={Settings.addBasename()} className={`${homeClassNames}${darkLinkClassNames} pl-0 pr-1 pr-sm-2 pr-md-2 pr-lg-3`} style={Styles.linkTextSmall}>Home</Nav.Link>
              <Nav.Link href={Settings.addBasename("#about")} className={`${linkClassNames}${darkLinkClassNames} pr-1 pr-sm-2 pr-md-2 pr-lg-3`} style={Styles.linkTextSmall}>About</Nav.Link>
              <Nav.Link href={Settings.addBasename("#articles")} className={`${linkClassNames}${darkLinkClassNames} pr-1 pr-sm-2 pr-md-2 pr-lg-3`} style={Styles.linkTextSmall}>Articles</Nav.Link>
              <Nav.Link href={Settings.linkedInLink} className={`${linkClassNames}${darkLinkClassNames}`} style={Styles.linkTextSmall}>LinkedIn</Nav.Link>
            </Nav>
          </div>
        </Navbar>

        {this.props.children}

      </div>
    );
  }
}

const Styles: { [id: string]: CSSProperties; } = {
  linkText: {
    fontFamily: 'Asap',
    fontWeight: 600,
  },

  linkTextSmall: {
    fontFamily: 'Asap',
    fontWeight: 600,
    fontSize: '0.8rem',
  },
}
