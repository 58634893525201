import React from "react";
import { Helmet } from "react-helmet";

import Settings from "../utils/Settings";

export interface OpenGraphImage {
  src: string,
  type: string,
  width: number,
  height: number,
}

interface Props {
  subtitle?: string,
  description?: string,
  isBlog?: boolean,
  sharingImage?: OpenGraphImage,
  sharingUrl?: string,
  noIndex?: boolean,
  author?: string,
  keywords?: string,
  isStartPage?: boolean,
}

function cutText(text?: string, length?: number): string {
  if (!text) {
    return ''
  }

  if (!length) {
    return text
  }

  return text.length > length ? text.substring(0, length - 3) + "..." : text;
}

const OpenGraph = {
  TYPE: `website`,
  LOCALE: `en_US`,
  MAX_DESCRIPTION_LENGTH: 300,
}

const Twitter = {
  SITE: `@${Settings.twitterHandle}`,
  CardType: {
    DEFAULT: "summary",
    WITH_IMAGE: "summary_large_image",
  },
}

const Robots = {
  HIDE_PAGE: "noindex, nofollow"
}

const Defaults = {
  AUTHOR: Settings.author,
  TITLE: Settings.author,
  DESCRIPTION: Settings.slogan,
  KEYWORDS: Settings.keywords,
  URL: Settings.getAbsoluteUrl(),
  IMAGE: {
    src: Settings.getAbsoluteUrl(Settings.defaultImageDir, Settings.defaultSharingThumbnail.name),
    type: Settings.defaultSharingThumbnail.type,
    width: Settings.defaultSharingThumbnail.width,
    height: Settings.defaultSharingThumbnail.height,
  },
}

export default class Meta extends React.Component<Props, any> {
  author: string
  title: string
  description: string
  keywords: string
  url: string
  image?: OpenGraphImage

  constructor(props: Props) {
    super(props)

    this.author = this.props.author || Defaults.AUTHOR
    this.title = this.props.subtitle || Defaults.TITLE // 65 max?
    this.description = this.props.description || Defaults.DESCRIPTION // 155 max?
    this.keywords = this.props.keywords || Defaults.KEYWORDS
    this.url = this.props.sharingUrl || Defaults.URL
    this.image = this.props.sharingImage || Defaults.IMAGE
  }

  render() {
    const renderedAdditionalMeta = this.props.noIndex ? this.renderNoIndex() : this.renderDefault()
    return (
      <Helmet>
        <title>{this.title}</title>
        {renderedAdditionalMeta}
        {this.props.isStartPage ? this.renderStartPageHeadElements() : null}
      </Helmet>
    )
  }

  createStructuredData() {
    const structuredData = {
      "@context": "https://schema.org",
      "@type": "Organization",
      image: Settings.getAbsoluteUrl(Settings.defaultImageDir, Settings.defaultHiresDefaultThumbnail.name),
      logo: Settings.getAbsoluteUrl(Settings.defaultImageDir, Settings.defaultHiresSquareThumbnail.name),
      url: Defaults.URL,
      sameAs: [
        'https://tobiaskaechele.co.uk',
        'https://tobiaskaechele.de',
        'https://tkae.de',
        'https://linkedin.com/in/tobiaskaechele',
        'https://github.com/tobiaskaechele',
      ],
      name: Defaults.TITLE,
      description: Defaults.DESCRIPTION,
      keywords: Defaults.KEYWORDS,
      contactPoint: {
        "@type": "ContactPoint",
        url: Settings.getAbsoluteUrl("#contact"),
        contactType: "Customer Service",
        availableLanguage: ["English"]
      }
    };

    return structuredData;
  }

  renderStartPageHeadElements() {
    return [
      <script type="application/ld+json">{JSON.stringify(this.createStructuredData())}</script>,
    ]
  }

  renderNoIndex() {
    const robots = Robots.HIDE_PAGE

    return <meta name="robots" content={robots} />
  }

  renderDefault() {
    return [
      this.renderMetaDefault(),
      this.renderMetaOpenGraph(),
      this.renderMetaOpenGraphImage(),
      this.renderMetaTwitter(),
    ]
  }

  renderMetaDefault() {
    return [
      <meta name="author" content={this.author} />,
      <meta name="description" content={this.description} />,
      <meta name="keywords" content={this.keywords} />,
    ]
  }

  renderMetaOpenGraph() {
    const openGraphUrl = this.url
    const openGraphTitle = this.title // 35 max?
    const openGraphDescription = cutText(this.description, OpenGraph.MAX_DESCRIPTION_LENGTH) // 65 max?
    const openGraphType = OpenGraph.TYPE
    const openGraphSiteName = this.author
    const openGraphLocale = OpenGraph.LOCALE

    return [
      <meta property="og:url" content={openGraphUrl} />,
      <meta property="og:title" content={openGraphTitle} />,
      <meta property="og:locale" content={openGraphLocale} />,
      <meta property="og:site_name" content={openGraphSiteName} />,
      <meta property="og:description" content={openGraphDescription} />,
      <meta property="og:type" content={openGraphType} />,
    ]
  }

  renderMetaOpenGraphImage() {
    if (!this.image) {
      return null
    }

    return [
      <meta property="og:image" content={this.image.src} />,
      <meta property="og:image:type" content={this.image.type} />,
      <meta property="og:image:width" content={this.image.width + ''} />,
      <meta property="og:image:height" content={this.image.height + ''} />,
    ]
  }

  renderMetaTwitter() {
    const twitterCreator = this.author
    const twitterSite = Twitter.SITE
    const twitterTitle = this.title
    const twitterDescription = this.description
    const twitterCard = this.image ? Twitter.CardType.WITH_IMAGE : Twitter.CardType.DEFAULT

    return [
      <meta property="twitter:creator" content={twitterCreator} />,
      <meta property="twitter:site" content={twitterSite} />,
      <meta property="twitter:title" content={twitterTitle} />,
      <meta property="twitter:description" content={twitterDescription} />,
      <meta property="twitter:card" content={twitterCard} />,
    ]
  }
}

/*
TWITTER

A URL to a unique image representing the content of the page. You should not use a generic image such as your website logo, author photo,
or other image that spans multiple pages. Images for this Card support an aspect ratio of 2:1 with minimum dimensions of 300x157 or maximum
of 4096x4096 pixels. Images must be less than 5MB in size. JPG, PNG, WEBP and GIF formats are supported. Only the first frame of an
animated GIF will be used. SVG is not supported.

FACEBOOK

Use images that are at least 1080 pixels in width for best display on high resolution devices. At the minimum, you should use images that
are 600 pixels in width to display image link ads. We recommend using 1:1 images in your ad creatives for better performance with image
link ads.

Pre-cache your images by running the URL through the URL Sharing Debugger tool to pre-fetch metadata for the website. You should also do
this if you update the image for a piece of content.

Use og:image:width and og:image:height Open Graph tags to specify the image dimensions to the crawler so that it can render the image
immediately without having to asynchronously download and process it.

FONTS
<link href="https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,300;0,400;0,500;0,600;0,700;0,900;1,300;1,400;1,500;1,700;1,900&display=swap" rel="stylesheet" />
        <link href="https://fonts.googleapis.com/css2?family=Source+Sans+Pro:ital,wght@0,200;0,300;0,400;0,600;0,700;0,900;1,200;1,300;1,400;1,600;1,700;1,900&display=swap" rel="stylesheet" />
        <link href="https://fonts.googleapis.com/css2?family=Asap:ital,wght@0,400;0,500;0,600;0,700;1,400;1,500;1,600;1,700&display=swap" rel="stylesheet" />
        <link href="https://fonts.googleapis.com/css2?family=Fira+Code:wght@300;400;500;600;700&display=swap" rel="stylesheet" />
{
  family: `Roboto`,
  variants: ["300","300i","400","400i","500","500i","600","600i","700","700i","900","900i"],
},
{
  family: `Source Sans Pro`,
  variants: ["300","300i","400","400i","600","600i","700","700i","900","900i"],
},
{
  family: `Asap`,
  variants: ["400","400i","500","500i","600","600i","700","700i"],
},
{
  family: `Fira Code`,
  variants: ["300","400",500","600","700"],
},
*/
